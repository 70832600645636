<template>
    <div>
        <vs-row>
            <vs-col xs="12" class="text-center">
                <h3>Need Help?</h3>
                <p class="mt-3">Our customer first support service is available on the following channels. Choose one that you're comfortable with.</p>
            </vs-col>
        </vs-row>
        <vs-row class="mt-5">
            <vs-col vs-xs="12" vs-sm="4" vs-lg="4" class="text-center mt-3">
                <a href="https://wa.me/919876543210">
                    <vx-card>
                        <font-awesome-icon :icon="IconWhatsApp" size="3x" style="color: #075e54"/>
                        <h3 class="mt-5">+91-9876543210</h3>
                        <p class="text-grey mt-2">Chat with us on WhatsApp to get your queries resolved faster</p>
                    </vx-card>
                </a>
            </vs-col>
            <vs-col vs-xs="12" vs-sm="4" vs-lg="4" class="text-center mt-3">
                <a href="https://wa.me/919876543210">
                    <vx-card>
                        <font-awesome-icon :icon="IconEnvelope" size="3x" style="color: #075e54"/>
                        <h3 class="mt-5">support@legully.com</h3>
                        <p class="text-grey mt-2">Mail us your queries and we'll get in touch ASAP.</p>
                    </vx-card>
                </a>
            </vs-col>
            <vs-col vs-xs="12" vs-sm="4" vs-lg="4" class="text-center mt-3">
                <a href="https://wa.me/919876543210">
                    <vx-card>
                        <font-awesome-icon :icon="IconPhone" size="3x" style="color: #075e54"/>
                        <h3 class="mt-5">+91-9876543210</h3>
                        <p class="text-grey mt-2">Call us to speak to our support experts and get your queries resolved.</p>
                    </vx-card>
                </a>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>

import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"

export default {
    computed: {
        IconWhatsApp: () => faWhatsapp,
        IconEnvelope: () => faEnvelope,
        IconPhone: () => faPhone,
    }
}
</script>